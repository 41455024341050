
import {Component, Vue} from "vue-property-decorator";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {
  IProcessedTaskChilds,
  IslemDurum,
  ITask,
  PlayerDurum
} from "@/plugins/uyap-plugin/store/modules/GorevYoneticisiTypes";
import {TaskDurum} from "@/plugins/uyap-plugin/enum/TaskDurum";
import {TaskTuru, TaskTuruLabel} from "@/plugins/uyap-plugin/enum/TaskTuru";
import Draggable from "vuedraggable";
import {ProgramPaths} from "@/enum/ProgramPaths";

@Component({
  computed: {
    IslemDurum(){
      return IslemDurum;
    }
  },
  components: {DeleteDialog, Draggable}
})

export default class DevamEdenGorevList extends Vue {
  taskDurum = TaskDurum;
  taskTuru = TaskTuru;
  taskTuruLabel = TaskTuruLabel;
  playerDurum = PlayerDurum;
  onFly = false;
  isDeleteModeActive = false;
  deleting = false;
  silinecekTasks = [];
  headers = [
    {text: "", value: "drag"},
    {text: "İşlem Türü", value: "islemTuru"},
    {text: "Açıklama", value: "aciklama"},
    {text: "Oluşturulma Tarihi", value: "created_at"},
    {text: "Durum", value: "durum", sortable: false, align: 'center'},
    {text: "İşlemler", class: "sticky-header", value: "actions", width: '90', sortable: false, align: 'center'},
  ];

  get showProgressBar() {
    return this.$store.getters.getChildTasksStatus.length > 0;
  }

  get tamamlanmaDurumu() {
    let bitendosyalar = this.$store.getters.getChildTasksStatus.filter((child: IProcessedTaskChilds) => [TaskDurum.BITTI_BASARILI, TaskDurum.BITTI_HATALI, TaskDurum.BITTI_EKSIK, TaskDurum.YENIDEN_BASLATILDI].indexOf(child.status) != -1);
    let yuzde = (100 / this.$store.getters.getChildTasksStatus.length) * bitendosyalar.length;
    return yuzde;
  }

  get progressMessage() {
    let bitendosyalar = this.$store.getters.getChildTasksStatus.filter((child: IProcessedTaskChilds) => [TaskDurum.BITTI_BASARILI, TaskDurum.BITTI_HATALI, TaskDurum.BITTI_EKSIK, TaskDurum.YENIDEN_BASLATILDI].indexOf(child.status) != -1);
    return "İşlemler üzerinde çalışılıyor... (" + bitendosyalar.length + "/" + this.$store.getters.getChildTasksStatus.length + ")";
  }

  get tasks(): Array<ITask> {
    return this.$store.getters.getSiradakiGorevler;
  }

  get dragOptions() {
    return {
      animation: 200,
      group: "description",
      disabled: false,
      ghostClass: "ghost"
    };
  }

  get disableDrag() {
    return !!this.tasks.find(task => task.status == TaskDurum.ISLENIYOR) || this.onFly;
  }

  onDelete(task: ITask) {
    this.$store.dispatch('deleteAllTasks', task);
  }

  async onDeleteMultiple() {
    try {
      this.deleting = true;
      this.isDeleteModeActive = false;
      await this.$http.post('/api/v1/task/toplu-sil', {tasks_ids: this.silinecekTasks.map((item: ITask) => item.id)});
      this.silinecekTasks = [];
    } catch (e) {
      this.$toast.error('Silme işleminde hata oluştu. ' + e.message);
    } finally {
      await this.$store.dispatch('getTasksFromServer');
      this.deleting = false;
    }
  }

  onEnd(evt: any) {
    if (evt.oldIndex == evt.newIndex)
      return;
    this.onFly = true;
    let liste = [...this.tasks];
    let changedElement = liste[evt.oldIndex];
    changedElement.oncelik_sirasi = evt.newIndex + 1;
    liste.splice(evt.oldIndex, 1);
    if (evt.oldIndex > evt.newIndex) {
      for (let i = evt.newIndex; i < liste.length; i++) {
        liste[i].oncelik_sirasi = i + 2;
      }
    } else if (evt.oldIndex < evt.newIndex) {
      for (let i = evt.newIndex - 1; i >= 0; i--) {
        liste[i].oncelik_sirasi = i + 1;
      }
    }
    liste.push(changedElement);
    liste.sort((a, b) => (a.oncelik_sirasi - b.oncelik_sirasi));
    this.$http.post('/api/v1/task/onceliksirasi', liste).then(() => {
      this.$socket.client.emit("updateTasks");
      this.onFly = false;
    });
  }

  async durdurDevamEt() {
    if (this.$store.getters.getPlayerDurum == this.playerDurum.oto) {
      this.$socket.client.emit("player", this.playerDurum.durduruluyor);
    } else {
      this.$socket.client.emit("player", this.playerDurum.oto);
    }
  }

  detayaGit(task: ITask) {
    switch (task.task_type_id) {
      case this.taskTuru.takip_hazirla:
        this.$router.push(ProgramPaths.eicrapro + '/takip-hazirla-detay/' + task.id);
        break;
      case this.taskTuru.hizli_takip_ac:
      case this.taskTuru.takip_ac:
        this.$router.push(ProgramPaths.eicrapro + '/takip-ac-detay/' + task.id);
        break;
      case this.taskTuru.mts_takip_hazirla:
        this.$router.push(ProgramPaths.eicrapro + '/mts-takip-hazirla-detay/' + task.id);
        break;
      case this.taskTuru.mts_takip_ac:
        this.$router.push(ProgramPaths.eicrapro + '/mts-takip-ac-detay/' + task.id);
        break;
      case this.taskTuru.takip_sorgula:
        this.$router.push(ProgramPaths.eicrapro + '/takip-sorgula-detay/' + task.id);
        break;
      case this.taskTuru.evrak_gonder:
        this.$router.push(ProgramPaths.eicrapro + '/evrak-gonder-detay/' + task.id);
        break;
      case this.taskTuru.hazir_evrak_gonder:
        this.$router.push(ProgramPaths.eicrapro + '/hazir-evrak-gonder-detay/' + task.id);
        break;
      case this.taskTuru.makbuz_indir:
        this.$router.push(ProgramPaths.eicrapro + '/makbuz-indir-detay/' + task.id);
        break;
      case this.taskTuru.toplu_genel_talep_hazirla:
        this.$router.push(ProgramPaths.eicrapro + '/genel-talep-gonder-detay/' + task.id);
        break;
      case this.taskTuru.toplu_tebligat_hazirla:
        this.$router.push(ProgramPaths.eicrapro + '/tebligat-gonder-detay/' + task.id);
        break;
      case this.taskTuru.toplu_haciz_gonder:
        this.$router.push(ProgramPaths.eicrapro + '/haciz-gonder-detay/' + task.id);
        break;
      case this.taskTuru.harc_masraf_ode:
        this.$router.push(ProgramPaths.eicrapro + '/harc-masraf-ode-detay/' + task.id);
        break;
      case this.taskTuru.mts_odeme:
        this.$router.push(ProgramPaths.eicrapro + '/mts-ode-detay/' + task.id);
        break;
      case this.taskTuru.mts_tebligat_gonder:
        this.$router.push(ProgramPaths.eicrapro + '/mts-tebligat-gonder-detay/' + task.id);
        break;
      case this.taskTuru.evrak_indir:
        this.$router.push(ProgramPaths.eicrapro + '/evrak-indir-detay/' + task.id);
        break;
    }
  }
}
